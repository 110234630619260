import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const initialState = {
  data: {},
  status: STATUS_TYPES.INITIAL
};

export default function retailerReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.RETAILER.SET_DATA:
      return { ...state, data: payload };
    case ACTION_TYPES.USER.SET_STATUS:
      return { ...state, status: payload };
    default:
      return state;
  }
}
