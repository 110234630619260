import { useSelector } from "react-redux";

import { getUserData } from "store/selectors/user";

export function useRequireRetailerGuard() {
  const user = useSelector(getUserData);

  // If user is not logged in, do nothing
  // TODO: Issue MRP-2770
  // eslint-disable-next-line unicorn/no-null
  if (!user)
    // TODO: Issue MRP-2770
    // eslint-disable-next-line unicorn/no-null
    return null;

  // If staff member or admin, do nothing
  // TODO: Issue MRP-2770
  // eslint-disable-next-line unicorn/no-null
  if (user.is_staff || user.is_super)
    // TODO: Issue MRP-2770
    // eslint-disable-next-line unicorn/no-null
    return null;

  // TODO: Issue MRP-2770
  // eslint-disable-next-line unicorn/no-null
  return null;
}
