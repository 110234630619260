import { HStack, Image, Link as CLink } from "@chakra-ui/react";
import { bellIcon, notificationRedBg } from "static/images";
import { ButtonLink, Text } from "ui-kit";
import { EXTERNAL_MEETING_URL } from "areas/dashboard/constants";

/**
 * @type {typeof HStack}
 */
// TODO: Issue MRP-2775
// eslint-disable-next-line arrow-body-style
const LockedAccountNotification = () => {
  return (
    <HStack h="60px" spacing="3" bgImage={notificationRedBg} bgPosition="center" bgRepeat="no-repeat">
      <Image src={bellIcon} boxSize="4" alt="bell icon" aria-hidden="true" ml="5" />
      <Text textStyle="body2" color="white">
        Your account has not been activated or is paused.
      </Text>
      <ButtonLink
        variant="secondary"
        shadow="none"
        size="md"
        ml="auto"
        textTransform="none"
        as={CLink}
        isExternal
        href={EXTERNAL_MEETING_URL}
      >
        Schedule a call to activate
      </ButtonLink>
    </HStack>
  );
};

export default LockedAccountNotification;
