// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import React, { useState, useRef } from "react";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { useLifecycles } from "react-use";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import PropTypes from "prop-types";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { Spinner, Heading, Box } from "@chakra-ui/react";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import cx from "classnames";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { loadingTexts, TIME_PER_LOADING_TEXT } from "constants/loader-const";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { getRandomElement } from "shared/helpers";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import "./loader.scss";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
const Loader = ({ className, type, size, isVisible, isLoadingTextVisible }) => {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const [loadingText, setLoadingText] = useState("");

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const classNames = {
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    loaderTypes: {
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      fullScreen: "loader--pos-fullscreen",
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      fullPage: "loader--pos-fullpage",
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      absolute: "loader--pos-absolute",
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      relative: "loader--pos-relative"
    },
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    loaderSizes: {
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      small: "loader--size-small",
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      large: "loader--size-large"
    }
  };

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const loaderType = classNames.loaderTypes[type] || "";
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const loaderSize = classNames.loaderSizes[size] || "";

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const intervalRef = useRef();

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  useLifecycles(
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    () => {
      // TODO: Issue MRP-2775
      // eslint-disable-next-line no-use-before-define, linebreak-style
      setNewLoadingText();
      // TODO: Issue MRP-2775
      // eslint-disable-next-line no-use-before-define, linebreak-style
      intervalRef.current = setInterval(setNewLoadingText, TIME_PER_LOADING_TEXT);
    },
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    () => {
      // clears the interval reference on unmount to avoid calling `setInterval` forever
      // TODO: Issue MRP-2775
      // eslint-disable-next-line no-use-before-define, linebreak-style
      resetIntervalRef();
    }
  );

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const setNewLoadingText = () => {
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    const newLoadingText = getRandomElement(loadingTexts);
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    setLoadingText(newLoadingText);
  };

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const resetIntervalRef = () => {
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    clearInterval(intervalRef.current);
    // TODO: Issue MRP-2775
    // eslint-disable-next-line unicorn/no-null, linebreak-style
    intervalRef.current = null;
  };

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  return (
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style, line-comment-position
    isVisible && // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      (<div className={cx("loader", loaderType, loaderSize, className, { "loader--hidden": !isVisible })}>
        {/* TODO: Issue MRP-2775 */}
        {/* eslint-disable-next-line linebreak-style */}
        <div className="loader__spinner">
          {/* TODO: Issue MRP-2775 */}
          {/* eslint-disable-next-line linebreak-style */}
          <Box mb="2">
            {/* TODO: Issue MRP-2775 */}
            {/* eslint-disable-next-line linebreak-style */}
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.base" size="lg" />
            {/* TODO: Issue MRP-2775 */}
            {/* eslint-disable-next-line linebreak-style */}
          </Box>
          {/* TODO: Issue MRP-2775 */}
          {/* eslint-disable-next-line linebreak-style */}
          {isLoadingTextVisible && (
            // TODO: Issue MRP-2775
            // eslint-disable-next-line linebreak-style
            (<Heading as="h1" size="lg">
              {/* TODO: Issue MRP-2775 */}
              {/* eslint-disable-next-line linebreak-style */}
              {loadingText}
              {/* TODO: Issue MRP-2775 */}
              {/* eslint-disable-next-line linebreak-style */}
            </Heading>)
          )}{/* TODO: Issue MRP-2775 */}
          {/* eslint-disable-next-line linebreak-style */}

        </div>
        {/* TODO: Issue MRP-2775 */}
        {/* eslint-disable-next-line linebreak-style */}
      </div>)
  );
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
Loader.propTypes = {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  className: PropTypes.string,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  type: PropTypes.oneOf(["base", "fullScreen", "fullPage", "absolute", "relative"]),
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  size: PropTypes.oneOf(["base", "small", "large"]),
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  isVisible: PropTypes.bool,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  isLoadingTextVisible: PropTypes.bool
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
Loader.defaultProps = {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  className: "",
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  type: "base",
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  size: "base",
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  isVisible: false,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  isLoadingTextVisible: false
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
export default Loader;