export const retailerDisplayNumber = (id) => String(id ?? "").padStart(5, "0");

/**
 * @typedef {{ is_active: boolean }} Retailer
 * @typedef {{
 *   metrics: object,
 *   sales: object,
 *   purchases: object
 * }} ordersSummary
 *
 * @param {{retailer: Retailer}}
 * @returns boolean
 */
export const isRetailerALockedAccount = ({ retailer = {} }) => {
  const salesChannels = retailer?.sales_channels || [];
  return salesChannels.length === 0;
};
