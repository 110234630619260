import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  SimpleGrid
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "react-use";
import { chunk } from "lodash";

import { Form, Heading, SmartInput } from "ui-kit";

import { setRetailer, unsetRetailer } from "store/actions/auth";
import { clearCart } from "store/actions/cart";
import { getRetailerId } from "store/selectors/auth";
// TODO: Issue MRP-2775
// eslint-disable-next-line import/no-unresolved
import { Icon, Refresh } from "ui-kit/icon";
import { getUserRetailerName, getUserRetailerId } from "store/selectors/user";

export const RetailerImpersonateModal = ({ disclosure, modalProps = {} }) => {
  const { isOpen, onClose } = disclosure;
  const dispatch = useDispatch();
  const defaultRetailerId = useSelector(getRetailerId);

  const currentRetailerName = useSelector(getUserRetailerName);
  const currentRetailerId = useSelector(getUserRetailerId);

  const [recentRetailers, setRecentRetailers] = useLocalStorage("recent-retailers", [], {
    serializer: val => JSON.stringify(val),
    deserializer: val => JSON.parse(val)
  });

  const [recentRetailerObjects, setRecentRetailerObjects] = useLocalStorage(
    "recent-retailer-objects",
    {},
    {
      serializer: val => JSON.stringify(val),
      deserializer: val => JSON.parse(val)
    }
  );

  useEffect(() => {
    setRecentRetailerObjects({ ...recentRetailerObjects, [currentRetailerId]: currentRetailerName });
  }, [currentRetailerName, currentRetailerId]);

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const clearRetailer = useCallback(() => {
    dispatch(unsetRetailer());
    dispatch(clearCart());
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      recentRetailers.unshift(data.retailerId);
      // TODO: Issue MRP-2775
      // eslint-disable-next-line unicorn/prefer-spread
      const recentRetailersArray = Array.from(new Set(recentRetailers));
      const [remainingRetailersArray, excessRetailersArray] = chunk(recentRetailersArray, 6);
      setRecentRetailers(remainingRetailersArray);

      while (excessRetailersArray?.length > 0) {
        const last = excessRetailersArray.pop();
        delete recentRetailerObjects[last];
      }
      setRecentRetailerObjects(recentRetailerObjects);

      dispatch(setRetailer(data.retailerId));
      dispatch(clearCart());
    },
    [dispatch, recentRetailers]
  );
  const handleClickRetailer = value => {
    setValue("retailerId", value);
    handleSubmit(onSubmit)();
  };

  const resetRecentRetailers = () => {
    setRecentRetailers([]);
    setRecentRetailerObjects([]);
  };

  const getRetailerIdButtonLabel = id => {
    const retailerName = recentRetailerObjects[id];
    return retailerName ? `${retailerName} (${id})` : `RETAILER ${id}`;
  };

  return (
    <Portal>
      <Modal __css={{ mt: 10 }} isCentered isOpen={isOpen} onClose={onClose} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Who do you want to impersonate?</ModalHeader>
            <ModalBody pb="4">
              <SmartInput
                name="retailerId"
                label="Retailer ID"
                data-testid="impersonate-form-retailer-id"
                rules={{
                  required: "Retailer ID is required"
                }}
              />
              {/* TODO: Issue MRP-2775 */}
              {/* eslint-disable-next-line no-implicit-coercion, unicorn/explicit-length-check */}
              {!!recentRetailers.length && (
                <>
                  <Heading my="4" d="inline-flex" alignItems="center" textStyle="body">
                    Recent Retailers{" "}
                    <IconButton
                      size="sm"
                      icon={<Icon as={Refresh} boxSize="5" />}
                      ml="2"
                      transform="scale(0.7)"
                      title="Reset Recent Retailers"
                      onClick={resetRecentRetailers}
                      variant="secondary"
                    />
                  </Heading>
                  <SimpleGrid columns={2} gap="3">
                    {/* TODO: Issue MRP-2775 */}
                    {/* eslint-disable-next-line arrow-body-style */}
                    {recentRetailers.map(id => {
                      return (
                        <Button w="full" size="sm" onClick={() => handleClickRetailer(id)} key={id} variant="ghost">
                          {getRetailerIdButtonLabel(id)}
                        </Button>
                      );
                    })}
                  </SimpleGrid>
                </>
              )}
            </ModalBody>
            <ModalCloseButton />
            <ModalFooter borderTopWidth="1px" justifyContent="space-between" sx={{ gap: "2" }}>
              {defaultRetailerId && (
                <Button type="button" size="sm" variant="tertiary" onClick={clearRetailer}>
                  Clear
                </Button>
              )}
              <Button ml="auto" type="submit" size="sm" data-testid="impersonate-form-retailer-button">
                Impersonate
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Portal>
  );
};
