export default function mapUniqueListings(results) {
  const uniqueRegistry = {};
  const uniqueListings = [];

  for (const listing of results) {
    if (!uniqueRegistry[listing.id]) {
      uniqueRegistry[listing.id] = true;
      uniqueListings.push(listing);
    }
  }

  return uniqueListings;
}
