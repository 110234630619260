import { POPUP_TYPES } from "constants/popups.const.js";

import PopupCart from "components/popup-manager/popup-cart/popup-cart";
import Popup from "components/popup-manager/popup/popup";

import "./popup-manager.scss";

const PopupManager = ({ doRemovePopup, popups, filters }) => {
  const visiblePopups = popups.filter(popup => filters.includes(popup.type));

  return (
    (<ul className="popup-manager" id="popup-manager">
      {/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line no-implicit-coercion, unicorn/explicit-length-check */}
      {!!visiblePopups.length && (
        <div className="popup-manager__overlay" onClick={() => doRemovePopup(visiblePopups[0].id)} />
      )}
      {visiblePopups.map(popup =>
        popup.type === POPUP_TYPES.CART ? (
          <PopupCart key={popup.id} removePopup={() => doRemovePopup(popup.id)} {...popup} />
        ) : (
          <Popup key={popup.id} removePopup={() => doRemovePopup(popup.id)} {...popup} />
        )
      )}
    </ul>)
  );
};

export default PopupManager;
