import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BRAND_CREATED_BY_RETAILER = "Brand Created by Retailer";
const BRAND_AUTHORIZED_FOR_RETAILER = "Brand Authorized for Retailer";
const TRANSACTION_CREATED = "Transaction Created";
const PRODUCT_SEARCH = "Product Searched";
const VERIFICATION_MATCHING_COMPLETED = "Verification Matching Completed";
const VERIFICATION_MATCHING_SEARCH = "Verification Matching Search";

export const identify = user => {
  if (process.env.NODE_ENV === "production" && !process.env.JEST_WORKER_ID) {
    Sentry.setUser({ email: user.email });

    const userObj = {
      id: user.email,
      email: user.email,
      is_staff: user.is_staff,
      active_retailer: user.active_retailer,
      full_name: `${user.first_name} ${user.last_name}`,
      first_name: user.first_name,
      last_name: user.last_name,
      role: user.is_staff || user.is_super ? "internal" : "external",
      integrations: user.integrations
    };
    const accountObj = user.retailer
      ? {
          id: user.retailer.id,
          state: user.retailer.state,
          status: user.retailer.is_active ? "active" : "not-active",
          name: user.retailer.business_name,
          creationDate: user.retailer.created
        }
      : {};

    const groupCallback = user.retailer
      ? () => {
          window.analytics.group(accountObj.id, accountObj);
        }
      : undefined;

    window.analytics.identify(userObj.id, userObj, {}, groupCallback);
  }
};

export const track = (event, data) => {
  if (process.env.NODE_ENV === "production" && !process.env.JEST_WORKER_ID) {
    window.analytics.track(event, data);
  }
};

export const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && !process.env.JEST_WORKER_ID) {
      window.analytics.page();
    }
  }, [location.key]);

  // TODO: Issue MRP-2772
  // eslint-disable-next-line unicorn/no-null
  return null;
};

export function formatProductViewed({ listing, user }) {
  return {
    product_id: listing.productvariation.product.id,
    listing_id: listing.id,
    user_id: listing.provider ?? listing.requestor,
    retailer_id: listing.provider ?? listing.requestor,
    provider_id: listing.provider,
    receiver_id: user.retailer?.id,
    receiver_city: user.retailer?.city,
    receiver_state: user.retailer?.state,
    distance: listing.distance,
    sku: listing.productvariation.product.sku,
    // top_category: "",
    // mid_category: "",
    // base_category: "",
    name: listing.productvariation.product.name,
    brand: listing.brand_name,
    color: listing.productvariation.color,
    whole_price: listing.productvariation.product.price,
    listing_price: listing.unit_price
  };
}

export function formatTransactionCreated({ listing, user }) {
  return {
    // order_id: "",
    listing_id: listing.id,
    user_id: listing.provider ?? listing.requestor,
    retailer_id: listing.provider ?? listing.requestor,
    provider_id: listing.provider,
    receiver_id: user.retailer.id,
    receiver_city: user.retailer.city,
    receiver_state: user.retailer.state,
    distance: listing.distance,
    // is_b2c: "",
    listing_added_type: listing.listing_type,
    // total: "",
    // fee: "",
    // shipping: "",
    // discount: "",
    // coupon: "",
    product_id: listing.productvariation.product.id,
    sku: listing.productvariation.product.sku,
    brand: listing.brand_name,
    whole_price: listing.productvariation.product.price
    // listing_price: "",
    // size_quantity: ""
  };
}

export function formatSearchTracking(searchString) {
  return {
    query: searchString
  };
}

export function formatProductRemoved({ listing, user }) {
  return {
    listing_id: listing.id,
    user_id: listing.provider ?? listing.requestor,
    retailer_id: listing.provider ?? listing.requestor,
    provider_id: listing.provider,
    receiver_id: user.retailer.id,
    receiver_city: user.retailer.city,
    receiver_state: user.retailer.state,
    distance: listing.distance,
    listing_added_type: listing.listing_type,
    product_id: listing.productvariation.product.id,
    sku: listing.productvariation.product.sku,
    // top_category: "",
    // mid_category: "",
    // base_category: "",
    name: listing.productvariation.product.name,
    brand: listing.brand_name,
    whole_price: listing.productvariation.product.price,
    listing_price: listing.unit_price
  };
}

export function formatProductAdded({ listing, user }) {
  return {
    product_id: listing.productvariation.product.id,
    listing_id: listing.id,
    user_id: listing.provider ?? listing.requestor,
    retailer_id: listing.provider ?? listing.requestor,
    provider_id: listing.provider,
    receiver_id: user.retailer.id,
    receiver_city: user.retailer.city,
    receiver_state: user.retailer.state,
    distance: listing.distance,
    sku: listing.productvariation.product.sku,
    // top_category: "",
    // mid_category: "",
    // base_category: "",
    name: listing.productvariation.product.name,
    brand: listing.brand_name,
    color: listing.productvariation.color,
    whole_price: listing.productvariation.product.price,
    listing_price: listing.unit_price,
    // shipping_cost: "",
    total_cost: listing.totalPrice,
    // fee: "",
    // discount: "",
    quantity: listing.totalUnits
  };
}

export function trackBrandCreated({ retailerId, brandName }) {
  track(BRAND_CREATED_BY_RETAILER, { retailerId, brandName });
}

export function trackBandAuthorized({ retailerId, brandName }) {
  track(BRAND_AUTHORIZED_FOR_RETAILER, { retailerId, brandName });
}

export function trackProductSearch(data) {
  const formattedData = formatSearchTracking(data);
  track(PRODUCT_SEARCH, formattedData);
}

export function trackTransactionCreated({ listing, user }) {
  const formattedTransactionCreated = formatTransactionCreated({ listing, user });
  track(TRANSACTION_CREATED, formattedTransactionCreated);
}

export function trackVerificationSearch(data) {
  track(VERIFICATION_MATCHING_SEARCH, data);
}

export function trackVerificationCompleted(data) {
  track(VERIFICATION_MATCHING_COMPLETED, data);
}
