import { ACTION_TYPES } from "constants/action-types";

const initialState = {
  popups: [],
  latestId: 0
};

export default function popupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.POPUP.ADD_POPUP:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const newId = state.latestId + 1;

      return {
        latestId: newId,
        popups: [...state.popups, { ...payload, id: newId }]
      };

    case ACTION_TYPES.POPUP.REMOVE_POPUP:
      return { ...state, popups: state.popups.filter(t => t.id !== payload) };

    case ACTION_TYPES.POPUP.CLEAR_POPUP:
      return initialState;

    default:
      return state;
  }
}
