// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { chakra, useBreakpointValue } from "@chakra-ui/react";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import PropTypes from "prop-types";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { SIDE_NAV_WIDTH } from "components/layout/constants";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import Loader from "components/shared/loader/loader";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
const LoaderBox = chakra(Loader);

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
const PageLoaderWrapper = ({ children, isLoaded }) => {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const offset = useBreakpointValue({
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    base: {},
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    lg: {
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      left: SIDE_NAV_WIDTH,
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      width: `calc(100vw - ${SIDE_NAV_WIDTH})`
    }
  });

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  return isLoaded ? <>{children}</> : <LoaderBox type="fullPage" {...offset} isLoadingTextVisible isVisible />;
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
PageLoaderWrapper.propTypes = {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  children: PropTypes.node,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  isLoaded: PropTypes.bool.isRequired
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
PageLoaderWrapper.defaultProps = {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line unicorn/no-null, linebreak-style
  children: null
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
export default PageLoaderWrapper;