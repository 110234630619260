// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

import { getUserDataStatus } from "./user";

export function getStatus(state) {
  return state.authentication.status;
}

export function getToken(state) {
  return state.authentication.token;
}

export function getRetailerId(state) {
  return state.authentication.retailerId;
}

export function getIsLoggedIn(state) {
  return getUserDataStatus(state) === STATUS_TYPES.LOADED;
}