import { DEFAULT_LOCALE } from "shared/formatting";

export const SentenceFormatter = new Intl.ListFormat(DEFAULT_LOCALE, { style: "long", type: "conjunction" });
export const Pluralizer = new Intl.PluralRules(DEFAULT_LOCALE, { type: "cardinal" });

export const CACHED_VERIFICATIONS_COUNT = 10;

export const FORM_ERROR_NAMES = {
  category_id: "Category",
  unit_price: "Price",
  wholesale_price: "Wholesale Price"
};

const PRODUCT_PATTERN_NAMES = [
  "Animal",
  "Argyle",
  "Camouflage",
  "Floral",
  "Geometric",
  "Gingham",
  "Graphic",
  "Paisley",
  "Patterned",
  "Plaid & Check",
  "Polka Dot",
  "Solid",
  "Stripe",
  "Tie-Dye"
];
// TODO: Issue MRP-2767
// eslint-disable-next-line unicorn/no-array-reduce
export const PRODUCT_PATTERN_OPTIONS = PRODUCT_PATTERN_NAMES.reduce((memo, name) => [...memo, { value: name, label: name }], []);

export const PRODUCT_COLORS = [
  "Beige",
  "Black",
  "Blue",
  "Brown",
  "Gold",
  "Green",
  "Grey",
  "Orange",
  "Pink",
  "Purple",
  "Red",
  "Silver",
  "White",
  "Yellow"
];

// TODO: Issue MRP-2767
// eslint-disable-next-line unicorn/no-array-reduce
export const PRODUCT_COLOR_OPTIONS = PRODUCT_COLORS.reduce((memo, name) => [...memo, { value: name, label: name }], []);

export const PRODUCT_MATERIALS = [
  "Acrylic",
  "Cashmere",
  "Cashmere Blend",
  "Corduroy",
  "Cotton",
  "Cotton Blend",
  "Denim",
  "Down",
  "Down Alternative",
  "Elastane",
  "Faux Fur",
  "Faux Leather",
  "Faux Suede",
  "Flannel",
  "Fleece",
  "Fur",
  "Leather",
  "Linen",
  "Linen Blend",
  "Lycra",
  "Lyocell",
  "Merino",
  "Merino Blend",
  "Modal",
  "Nylon",
  "Nylon Blend",
  "Polyamide",
  "Polyester",
  "Rayon",
  "Recycled Material",
  "Silk",
  "Silk Blend",
  "Suede",
  "Velvet",
  "Viscose",
  "Viscose Blend",
  "Wool",
  "Wool Blend",
  "Canvas",
  "Knit",
  "Patent Leather",
  "PVC",
  "Recycled Plastic",
  "Rubber",
  "Satin",
  "Brass",
  "Ceramic",
  "Crystal",
  "Cubic Zirconia",
  "Diamond",
  "Emerald",
  "Faux Pearl",
  "Gemstones",
  "Goldtone",
  "Gunmetal Tone",
  "Lab Created Gemstones",
  "Lab Grown Diamond",
  "Lace",
  "Lucite",
  "Metal",
  "Mother of Pearl",
  "Pearl",
  "Platinum",
  "Rose Gold",
  "Rose Goldtone",
  "Ruby",
  "Sapphire",
  "Silicone",
  "Silvertone",
  "Stainless Steel",
  "Sterling Silver",
  "Straw",
  "Touch Tech",
  "White Gold",
  "Yellow Gold",
  "Yellow Goldtone",
  "Calf Hair",
  "Hemp",
  "Limestone",
  "Neoprene",
  "Polyurethane",
  "Pony Hair",
  "Carbon",
  "Fiberglass",
  "Non-Iron",
  "PVD",
  "Titanium"
];

// TODO: Issue MRP-2767
// eslint-disable-next-line unicorn/no-array-reduce
export const PRODUCT_MATERIAL_OPTIONS = PRODUCT_MATERIALS.reduce((memo, name) => [...memo, { value: name, label: name }], []);

export const STATUS_FILTER_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "ignored", label: "Ignored" }
];

export const MID_CATEGORIES_THAT_SHOW_PATTERN = ["Jackets & Coats", "Suits & Blazers", "Dresses", "Sweaters", "Swim"];

export const IMAGES_MIN_LENGTH = 1;

export const IMAGES_MAX_LENGTH = 10;
