import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const initialState = {
  data: {},
  status: STATUS_TYPES.INITIAL
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.USER.SET_DATA:
      return { ...state, data: payload };

    case ACTION_TYPES.USER.UNSET_DATA:
      return { ...state, data: {} };

    case ACTION_TYPES.USER.SET_STATUS:
      return { ...state, status: payload };

      case ACTION_TYPES.USER.SET_SELLER_TYPE:
        return {
          ...state,
          data: {
            ...state.data,
            sellerType: payload
          }
        };

    case ACTION_TYPES.USER.CLEAR:
      return {};

    default:
      return state;
  }
}
