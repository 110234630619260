import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { useMap } from "react-use";
import PropTypes from 'prop-types';

import Dropzone from "components/add-listing-new/dropzone";
import { fileKey } from "components/add-listing-new/helpers";

import { IMAGES_MAX_LENGTH } from "areas/verifications/constants";

const DropzoneModal = ({
    isOpen,
    onClose,
    onSave,
    addedFiles,
}) => {
    const [imageMap, { set: addImage, reset: resetImages, remove: removeImage }] = useMap();

    const handleClickSave = () => {
        onSave(imageMap);
        // TODO: Issue MRP-2774
        // eslint-disable-next-line no-use-before-define
        handleClickClose();
    }

    const handleClickClose = () => {
        resetImages();
        onClose();
    }

    const handleAddImage = image => {
        addImage(fileKey(image), image);
    };

    const handleRemoveImage = image => {
        removeImage(fileKey(image));
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="lg"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="md">
                    Upload files
                    <ModalCloseButton onClick={handleClickClose} size="sm"/>
                </ModalHeader>
                <ModalBody pb={2}>
                    <Dropzone
                        onImage={handleAddImage}
                        onRemoveImage={handleRemoveImage}
                        existingFileKeys={addedFiles.map(f => Object.keys(f)[0])}
                        maxFilesLength={IMAGES_MAX_LENGTH}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button size="sm" variant="outline" onClick={handleClickClose}>Cancel</Button>
                    {/* TODO: Issue MRP-2774 */}
                    {/* eslint-disable-next-line unicorn/explicit-length-check */}
                    <Button size="xs" ml={3} disabled={!Object.keys(imageMap).length} onClick={handleClickSave}>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

DropzoneModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default DropzoneModal;