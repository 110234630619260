import { mode } from "@chakra-ui/theme-tools";

// eslint-disable-next-line no-unused-vars
export default props => { return {
  "html, body": {
    color: mode("blue.darkest", "white")(props),
    // TODO: Eventually switch this to 110%?
    // TODO: Issue MRP-2774
    // eslint-disable-next-line line-comment-position
    fontSize: { base: "14px", sm: "16px" }
  },

  body: {
    "& a": {
      color: "primary"
    },
    "*::placeholder": {
      color: "rgba(0, 0, 0, 0.8)"
    }
  }
}; };
