// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import React, { useState } from "react";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import dayjs from "dayjs";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { Image, Box } from "@chakra-ui/react";

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { pendingImage, placeholder } from "static/images";
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import BannerOnImage from "../banner-on-image/banner-on-image";

/**
 * @type {React.FC<{
 *  className?: string,
 * src: string,
 * errorSrc: any,
 * fallbackSrc: any,
 * dateCreated?: string,
 * displayDaysListed?: boolean }>}
 */
// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
const LazyBackgroundImage = ({
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  src,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  errorSrc = pendingImage,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  fallbackSrc = placeholder,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  dateCreated,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  displayDaysListed = false,
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  condition = "",
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  ...rest
}) => // TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
{
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const [imgSrc, setImgSrc] = useState(() => src);

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const daysAgo = dayjs().diff(dayjs(dateCreated), "day");
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  const daysAgoText = daysAgo === 0 ? "listed today" : `${daysAgo} day${daysAgo > 1 ? "s" : ""} listed`;
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  let daysAgoColorScheme = "gray";
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  if (daysAgo > 90) {
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    daysAgoColorScheme = "red";
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
  } else if (daysAgo > 60) {
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    daysAgoColorScheme = "yellow";
  }

  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  return (
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    (<Box height="100%">
      {/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}
      <Image src={imgSrc} fallbackSrc={fallbackSrc} onError={() => setImgSrc(errorSrc)} loading="lazy" {...rest} />
      {/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}
      {displayDaysListed && <BannerOnImage text={daysAgoText} colorScheme={daysAgoColorScheme} variant="subtle" />}
      {/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}
      {condition?.toLowerCase() === "preloved" && (
        // TODO: Issue MRP-2775
        // eslint-disable-next-line linebreak-style
        (<BannerOnImage colorScheme="purple" text={condition} position="bottom" variant="subtle" />)
      )}{/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}

    </Box>)
  );
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
export default LazyBackgroundImage;