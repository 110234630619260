const heading = other => { return {
  fontFamily: "heading",
  lineHeight: "shorter",
  ...other
} };

const body = other => { return {
  fontFamily: "body",
  ...other
} };

export default {
  h1: heading({ fontSize: "6xl" }),
  h2: heading({ fontSize: "2.5rem" }),
  h3: heading({ fontSize: "3xl", fontFamily: "headingMedium" }),
  h4: heading({ fontSize: "2xl", fontFamily: "bodyBold" }),
  h5: heading({ fontSize: "lg", fontFamily: "bodyBold" }),
  maintitle: heading({ fontSize: "3.125rem" }),
  notice: heading({ fontSize: "1.375rem", fontFamily: "headingMedium" }),
  body: body({ fontSize: "lg" }),
  body2: body({ fontSize: "md" }),
  body3: body({ fontSize: "sm" }),
  body4: body({ fontSize: "sm", fontFamily: "bodyBold" }),
  body5: body({ fontSize: "xs" }),
  body6: body({ fontSize: "0.625rem" }),
  subtext: body({ fontSize: "xs" }),
  mono: body({ fontSize: "0.625rem", textTransform: "uppercase" })
};
