// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
import React, { useMemo } from "react";
// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
import PropTypes from "prop-types";
// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
import { Box, Stack, Flex } from "@chakra-ui/react";
// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
import { Icon } from "ui-kit";
// TODO: Issue MRP-2774
// eslint-disable-next-line import/no-unresolved, linebreak-style
import { Chevron } from "ui-kit/icon";

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
import { ArrowButton, NumberButton } from "./buttons";

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
const translateX = {
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  sm: "3px",
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  md: "5px",
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  lg: "8px"
};

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
const Pagination = ({ totalPages, pagesToShow, onGoToPage, currentPage, size = "md" }) => {
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const pagesToShowMid = Math.floor(pagesToShow / 2);
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const firstPage =
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    currentPage > pagesToShowMid ? Math.min(currentPage - pagesToShowMid, totalPages - pagesToShow + 1 || 1) : 1;
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const lastPage = firstPage + pagesToShow - 1 <= totalPages ? firstPage + pagesToShow - 1 : totalPages;
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const show3Dots = totalPages - lastPage >= 2;
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const showTotalPages = show3Dots || lastPage + 1 === totalPages;

  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const isPrevDisabled = currentPage <= 1 || totalPages === 1;
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const isNextDisabled = currentPage >= totalPages || totalPages === 1;

  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  const pages = useMemo(() => {
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    let pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    pagesArray = pagesArray.slice(firstPage - 1, lastPage);
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    pagesArray = show3Dots ? [...pagesArray, "..."] : pagesArray;
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    pagesArray = showTotalPages ? [...pagesArray, totalPages] : pagesArray;

    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    return pagesArray;
  }, // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  [totalPages, firstPage, lastPage, show3Dots, showTotalPages]);

  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  return (
    // TODO: Issue MRP-2774
    // eslint-disable-next-line linebreak-style
    (<Box as="nav" role="navigation" aria-label="Pagination Navigation">
      {/* TODO: Issue MRP-2774 */}
      {/* eslint-disable-next-line linebreak-style */}
      <Stack isInline as="ol" spacing={1}>
        {/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}
        <Flex as="li">
          {/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}
          <ArrowButton
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            isDisabled={isPrevDisabled}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            onClick={() => onGoToPage(1)}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            size={size}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            aria-label="Go to the first page"
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            icon={
              // TODO: Issue MRP-2774
              // eslint-disable-next-line linebreak-style
              <>
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
                <Icon as={Chevron} transform={`rotate(180deg) translateX(-${translateX[size]})`} size={size} />
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
                <Icon as={Chevron} transform={`rotate(180deg) translateX(${translateX[size]})`} size={size} />{/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}

              </>
            }
          />{/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}

        </Flex>
        {/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}
        <Flex as="li">
          {/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}
          <ArrowButton
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            isDisabled={isPrevDisabled}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            onClick={() => onGoToPage(currentPage - 1)}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            size={size}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            aria-label="Go to the previous page"
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            icon={<Icon as={Chevron} transform="rotate(180deg)" size={size} />}
          />{/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}

        </Flex>
        {/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}
        {pages.map(page => (
          // TODO: Issue MRP-2774
          // eslint-disable-next-line linebreak-style
          (<Flex as="li" key={page}>
            {/* TODO: Issue MRP-2774 */}
            {/* eslint-disable-next-line linebreak-style */}
            {page === "..." ? (
              // TODO: Issue MRP-2774
              // eslint-disable-next-line linebreak-style
              (<NumberButton
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                size={size}
                // Don't have the 3 dots do anything.
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                pointerEvents="none"
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                aria-hidden="true"
              >{/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}

                {page}
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
              </NumberButton>)
            ) : (
              // TODO: Issue MRP-2774
              // eslint-disable-next-line linebreak-style
              (<NumberButton
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                onClick={() => onGoToPage(page)}
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                size={size}
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                aria-label={`Go to page ${page}`}
                // TODO: Issue MRP-2774
                // eslint-disable-next-line linebreak-style
                isActive={currentPage === page}
                {...(currentPage === page ? { "aria-current": true } : {})}
              >{/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}

                {page}
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
              </NumberButton>)
            )}{/* TODO: Issue MRP-2774 */}
            {/* eslint-disable-next-line linebreak-style */}

          </Flex>)
        ))}{/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}

        <Flex as="li">
          {/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}
          <ArrowButton
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            isDisabled={isNextDisabled}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            onClick={() => onGoToPage(currentPage + 1)}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            size={size}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            aria-label="Go to the next page"
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            icon={<Icon as={Chevron} size={size} />}
          />{/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}

        </Flex>
        {/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}
        <Flex as="li">
          {/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}
          <ArrowButton
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            isDisabled={isNextDisabled}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            onClick={() => onGoToPage(totalPages)}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            size={size}
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            aria-label="Go to the last page"
            // TODO: Issue MRP-2774
            // eslint-disable-next-line linebreak-style
            icon={
              // TODO: Issue MRP-2774
              // eslint-disable-next-line linebreak-style
              <>
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
                <Icon as={Chevron} transform={`translateX(${translateX[size]})`} size={size} />
                {/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}
                <Icon as={Chevron} transform={`translateX(-${translateX[size]})`} size={size} />{/* TODO: Issue MRP-2774 */}
                {/* eslint-disable-next-line linebreak-style */}

              </>
            }
          />{/* TODO: Issue MRP-2774 */}
          {/* eslint-disable-next-line linebreak-style */}

        </Flex>
        {/* TODO: Issue MRP-2774 */}
        {/* eslint-disable-next-line linebreak-style */}
      </Stack>
      {/* TODO: Issue MRP-2774 */}
      {/* eslint-disable-next-line linebreak-style */}
    </Box>)
  );
};

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
Pagination.propTypes = {
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  totalPages: PropTypes.number.isRequired,
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  pagesToShow: PropTypes.number,
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  onGoToPage: PropTypes.func,
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  currentPage: PropTypes.number
};

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
Pagination.defaultProps = {
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  pagesToShow: 3,
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  onGoToPage: () => true,
  // TODO: Issue MRP-2774
  // eslint-disable-next-line linebreak-style
  currentPage: 0
};

// TODO: Issue MRP-2774
// eslint-disable-next-line linebreak-style
export default Pagination;