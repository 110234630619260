import { chakra } from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

const CForm = chakra("form");

// TODO: Issue MRP-2774
// eslint-disable-next-line arrow-body-style
const Form = ({ children, form, onSubmit = () => {}, ...rest }) => {
  return (
    <FormProvider {...form}>
      <CForm {...rest} onSubmit={form.handleSubmit(onSubmit)}>
        {children}
      </CForm>
    </FormProvider>
  );
};

export default Form;
