import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const { VITE_SENTRY_DSN, VITE_SENTRY_ENVIRONMENT } = import.meta.env;

class MRSentryService {
  // TODO: Issue MRP-2772
  // eslint-disable-next-line class-methods-use-this
  init() {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: VITE_SENTRY_DSN,
        integrations: [new BrowserTracing()],

        environment: VITE_SENTRY_ENVIRONMENT ?? "development",

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1
      });
    }
  }
}

const sentryService = new MRSentryService();

export default sentryService;
