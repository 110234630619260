import jwtDecode from "jwt-decode";
import { has } from "lodash";
import { DateTime } from "luxon";

/**
 * @typedef {{ email: string, retailer?: { id: number } }} User
 *
 * @param {User} user
 * @return {{
 *  email: string,
 *  key: string,
 *  name: string,
 *  firstName?: string,
 *  lastName?: string,
 *  custom: { retailer_id: number | null }
 * }}
 */
export function launchDarklyUser(user) {
  const { first_name: firstName, last_name: lastName } = userNames(user);

  const hasContact = firstName || lastName;

  return {
    email: user.email,
    key: user.email,
    firstName,
    lastName,
    name: hasContact ? `${firstName} ${lastName}` : user.email,
    custom: {
      retailer_id: user.retailer?.id
    }
  };
}

/**
 * @param {MRAPI.User} user
 * @param {{ first_name?: string, last_name?: string, [key: any]: string } | null} retailercontact
 * @return { first_name?: string, last_name?: string }
 */
// TODO: Issue MRP-2772
// eslint-disable-next-line unicorn/no-null
export function userNames(user, retailercontact = null) {
  if (has(user, "first_name")) {
    return {
      first_name: user.first_name,
      last_name: user.last_name
    };
  }

  return {
    first_name: retailercontact?.first_name ?? user?.retailercontact?.first_name,
    last_name: retailercontact?.last_name ?? user?.retailercontact?.last_name
  };
}

/**
 *
 * @param {string} token
 * @param {{ time: number }} options
 * @returns boolean
 */
export function isValidJwtToken(token, { time = new Date() } = {}) {
  if (!token) return false;

  try {
    /** @type {import('jwt-decode').JwtPayload} */
    const data = jwtDecode(token);
    const expiresAt = new Date(data.exp * 1000);
    if (DateTime.fromJSDate(expiresAt) < DateTime.fromJSDate(time)) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
}
