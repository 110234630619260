export default {
  baseStyle: {
    field: {
      _placeholder: {
        color: "gray.base"
      }
    }
  },
  variants: {
    // since we are already using the existing `outline` variant, override it with out own approach
    outline: {
      field: {
        bg: "white",
        boxShadow: "none",
        borderRadius: "full",
        borderColor: "gray.base",
        borderWidth: "1px",
        fontSize: "0.875rem",
        fontFamily: "CabinRegular",

        _focus: {
          borderColor: "blue.300"
        },

        _invalid: {
          borderColor: "secondary.red"
        }
      }
    }
  },
  defaultProps: {
    colorScheme: "blue",
    // default to outline when we use `<Input />` from Chakra or from `ui-kit`
    // TODO: Issue MRP-2774
    // eslint-disable-next-line line-comment-position
    variant: "outline"
  }
};
