import { createSelector } from "reselect";

// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const takeOneParam = (_, value) => value;

export const getCart = state => state.cart || {};

export const getCartStatus = createSelector(getCart, ({ status }) => status);

export const getIsCartPending = createSelector(getCartStatus, status => status === STATUS_TYPES.PENDING);

export const getCartItems = createSelector(getCart, ({ items }) => items || {});

export const getCartItemsList = createSelector(getCartItems, items => Object.values(items));

// TODO: Issue MRP-2773
// eslint-disable-next-line unicorn/no-null
export const getCartItem = createSelector(getCartItems, takeOneParam, (items, itemId) => items[itemId] || null);
export const getCartItemsByRetailer = createSelector(getCartItemsList, items =>
  // TODO: Issue MRP-2773
  // eslint-disable-next-line unicorn/no-array-reduce
  items.reduce((acc, item) => {
    if (acc[item.provider]) {
      acc[item.provider].push(item);
      acc[item.provider].totalUnits += item.totalUnits;
    } else {
      acc[item.provider] = [item];
      acc[item.provider].totalUnits = item.totalUnits;
    }

    return acc;
  }, {})
);

export const getTransactions = createSelector(getCart, cart => cart.transactions || {});
export const getTransactionByRetailer = createSelector(
  getTransactions,
  takeOneParam,
  // TODO: Issue MRP-2773
  // eslint-disable-next-line unicorn/no-null
  (transactions, retailerId) => transactions[retailerId] || null
);

export const getCartTotalUnits = createSelector(getCartItemsList, items =>
  // eslint-disable-next-line no-return-assign, unicorn/no-array-reduce
  items.reduce((acc, item) => (acc += item.totalUnits), 0)
);
