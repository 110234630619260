// Third Party
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

// Reducers
import { localStorageMiddleware } from "store/middlewares/local-storage-middleware";

import { sessionStorageMiddleware } from "../middlewares/session-storage-middleware";

import authentication from "./auth";
import brands from "./brands";
import cart from "./cart";
import categories from "./categories";
import errors from "./errors";
import filters from "./filters";
import listing from "./listing";
import listings from "./listings";
import order from "./order";
import popup from "./popup";
import retailer from "./retailer";
import sizes from "./sizes";
import user from "./user";

// Middleware

/*
 *
 */

// TODO: Issue MRP-2773
// eslint-disable-next-line no-underscore-dangle
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedReducer = combineReducers({
  user,
  authentication,
  errors,
  brands,
  categories,
  sizes,
  listings,
  listing,
  order,
  cart,
  popup,
  filters,
  retailer
});

const store = createStore(
  combinedReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware, localStorageMiddleware, sessionStorageMiddleware))
);

export default store;
