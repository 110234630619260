import { Alert, AlertDescription, AlertIcon, AlertTitle, Center, Link } from "@chakra-ui/react";

import { usingInternetExplorer } from "shared/helpers";

const InternetExplorerAlert = () => {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line unicorn/no-null
  if (!usingInternetExplorer())
    // TODO: Issue MRP-2775
    // eslint-disable-next-line unicorn/no-null
    return null;

  return (
    <Alert status="error" textAlign="center" variant="top-accent">
      <Center w="full">
        <AlertIcon />
        <AlertTitle mr={2}>Your browser is outdated!</AlertTitle>
        <AlertDescription as="p">
          Your Max Retail experience may be degraded. Give{" "}
          <Link color="blue.base" isExternal href="https://www.google.com/chrome/index.html">
            Chrome
          </Link>{" "}
          or{" "}
          <Link color="blue.base" isExternal href="https://www.mozilla.org/en-US/firefox/new/">
            Firefox
          </Link>{" "}
          a try!
        </AlertDescription>
      </Center>
    </Alert>
  );
};

export default InternetExplorerAlert;
