// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
import { Badge } from "@chakra-ui/react";

// TODO: Issue MRP-2775
// eslint-disable-next-line arrow-body-style, linebreak-style
const BannerOnImage = ({ text, position = "top", variant = "outline", colorScheme = "gray" }) => {
  // TODO: Issue MRP-2775
  // eslint-disable-next-line linebreak-style
  return (
    // TODO: Issue MRP-2775
    // eslint-disable-next-line linebreak-style
    (<Badge
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      position="absolute"
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      fontSize="14px"
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      zIndex={3}
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      px="10px"
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      ml={2}
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      mt={2}
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      lineHeight="24px"
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      colorScheme={colorScheme}
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      variant={variant}
      // TODO: Issue MRP-2775
      // eslint-disable-next-line linebreak-style
      left="1px"
      {...(position === "top" ? { top: "1px" } : { bottom: "9px" })}
    >{/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}

      {text}
      {/* TODO: Issue MRP-2775 */}
      {/* eslint-disable-next-line linebreak-style */}
    </Badge>)
  );
};

// TODO: Issue MRP-2775
// eslint-disable-next-line linebreak-style
export default BannerOnImage;