import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const initialState = {
  accessToken: "",
  // TODO: Issue MRP-2773
  // eslint-disable-next-line no-undef
  retailerId: sessionStorage.getItem("retailerId") || "",
  status: STATUS_TYPES.INITIAL
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.AUTH.SET_TOKEN:
      return { ...state, accessToken: action.payload.access };

    case ACTION_TYPES.AUTH.SET_RETAILER:
      return { ...state, retailerId: action.retailerId };

    case ACTION_TYPES.AUTH.SET_STATUS:
      return { ...state, status: action.payload };

    case ACTION_TYPES.AUTH.UNSET_TOKEN:
      return { ...state, accessToken: "" };

    case ACTION_TYPES.AUTH.UNSET_RETAILER:
      return { ...state, retailerId: "" };

    default:
      return state;
  }
}
