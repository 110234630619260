import { ACTION_TYPES } from "constants/action-types";
import { STATUS_TYPES } from "constants/status-types";

const initialState = {
  status: STATUS_TYPES.INITIAL,
  type: "sale",
  data: {}
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.ORDER.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.ORDER.SET_ACTION_STATUS:
      return { ...state, actionStatus: action.payload };
    case ACTION_TYPES.ORDER.SET_DATA:
      return { ...state, data: action.payload };
    case ACTION_TYPES.ORDER.SET_TYPE:
      return { ...state, type: action.payload };
    default:
      return state;
  }
}
