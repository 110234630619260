import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const initialState = {
  status: STATUS_TYPES.INITIAL,
  data: []
};

export default function sizesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SIZES.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.SIZES.SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
