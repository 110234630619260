export const THE_BAY_RETAILER_ID = 9434;
export const JOMA_SHOP_RETAILER_ID = 9418;
export const RUE_GUILT_RETAILER_ID = 9469;
export const SPO_RETAILER_ID = 6007;
export const POSHMARK_RETAILER_ID = 5842;
export const GRAILED_RETAILER_ID = 9530;
export const POSHMARK_2_RETAILER_ID = 9604;
export const POSHMARK_3_RETAILER_ID = 10_641;
export const POSHMARK_PRELOVED_RETAILER_ID = 10_281;
export const KIDIZEN_RETAILER_ID = 10_298;
export const QUEENLY_RETAILER_ID = 10_332;
export const MERCARI_RETAILER_ID = 10_230;
export const WALMART_RETAILER_ID = 9537;
export const EBAY_RETAILER_ID = 9603;
export const DIA_AND_CO_RETAILER_ID = 10_519;
export const YAYSAY_RETAILER_ID = 10_455;
export const TIENDAMIA_RETAILER_ID = 10_570;
export const REITMANS_RETAILER_ID = 10_575;
export const RWCO_RETAILER_ID = 10_657;
export const PENNINGTONS_RETAILER_ID = 10_658;

export const RTR_PROVIDER_ID = 5375;

export const SHIPPING_LABEL_OVERRIDE_RETAILERS = [RUE_GUILT_RETAILER_ID];
export const POSHMARK_RETAILER_IDS = [POSHMARK_RETAILER_ID, POSHMARK_2_RETAILER_ID, POSHMARK_3_RETAILER_ID];
export const RCL_RETAILER_IDS = [REITMANS_RETAILER_ID, RWCO_RETAILER_ID, PENNINGTONS_RETAILER_ID];

export const INTERNATIONAL_SHIPPING_RETAILERS = [
  THE_BAY_RETAILER_ID,
  GRAILED_RETAILER_ID,
  REITMANS_RETAILER_ID,
  RWCO_RETAILER_ID,
  PENNINGTONS_RETAILER_ID
];

// USE BELOW INSTEAD OF INTERNATIONAL_SHIPPING_RETAILERS - retailer ids may differ between environments
export const INTERNATIONAL_SHIPPING_RETAILERS_NAMES = ["Reitmans", "The Bay", "RW&CO", "Grailed", "Penningtons"];
