import APIHandler from "api/api-handler";

import { API_ROUTES } from "./const";

export const CartAPI = {
  createTransaction: data =>
    APIHandler.post({
      url: API_ROUTES.CART.TRANSACTION_BUY,
      body: data
    }),
  completeTransaction: transactionId =>
    APIHandler.patch({
      url: API_ROUTES.CART.generate_TRANSACTION_COMPLETE(transactionId),
      body: {}
    }),
  createShippingAddress: data =>
    APIHandler.post({
      url: API_ROUTES.CART.SETUP_DROPSHIP_TX,
      body: data
    }),
  submitCardNonce: nonce =>
    APIHandler.post({
      url: API_ROUTES.CART.CARDS,
      body: nonce
    }),
  createTransactionIntent: ({ transactionId, retailerId }) =>
    APIHandler.patch({
      url: API_ROUTES.CART.generate_PAYMENT_INTENT(transactionId),
      body: { HTTP_RETAILER_ID: retailerId }
    }),
  createCheckoutIntent: ({ transactionId, retailerId }) =>
    APIHandler.patch({
      url: API_ROUTES.CART.generate_CHECKOUT_INTENT(transactionId),
      body: { HTTP_RETAILER_ID: retailerId }
    }),
  completeSalesChannelTransaction: ({ transactionId, retailerId }) =>
    APIHandler.patch({
      url: API_ROUTES.CART.generate_SALES_CHANNEL_CHECKOUT_COMPLETE(transactionId),
      body: { HTTP_RETAILER_ID: retailerId }
    }),
  addPackingSlip: (transactionId, packingSlipUrl) =>
    APIHandler.post({
      url: API_ROUTES.CART.generate_ADD_PACKING_SLIP(transactionId),
      body: { packing_slip: packingSlipUrl }
    })
};
