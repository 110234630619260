import React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "ui-kit";

// TODO: Issue MRP-2774
// eslint-disable-next-line arrow-body-style
const UiThemeProvider = props => {
  return <ChakraProvider theme={theme} resetCSS {...props} />;
};

export default UiThemeProvider;
