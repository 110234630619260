import { theme as baseTheme } from "@chakra-ui/react";

const btnPadding = {
  // 6px
  // TODO: Issue MRP-2774
  // eslint-disable-next-line line-comment-position
  py: "0.375em",
  // 30px
  // TODO: Issue MRP-2774
  // eslint-disable-next-line line-comment-position
  px: "1.875rem"
};

const sizes = {
  xs: { h: "1.875rem", ...btnPadding },
  sm: { h: "1.875rem", ...btnPadding },
  md: { h: 10, ...btnPadding },
  lg: { h: "3.125rem", ...btnPadding }
};

export default {
  baseStyle: {
    ...baseTheme.components.Button.baseStyle,
    borderRadius: "full",
    fontSize: "1rem",
    fontWeight: "400",
    textTransform: "uppercase",

    _active: { opacity: 0.5 },
    _disabled: { opacity: 0.5 },
    _hover: {
      textDecor: "none",
      _disabled: { opacity: 0.4 }
    }
  },

  sizes,
  variants: {
    primary: () => { return {
      color: "white",
      bg: "primary",
      fontWeight: "normal",

      // Enforces colors for our primary buttons
      _hover: {
        bg: "blue.400",
        color: "white",
        _active: { bg: "blue.400" },
        _disabled: { bg: "blue.400" }
      }
    } },
    secondary: () => { return {
      color: "blue.darkest",
      bg: "white",
      fontWeight: "normal",

      boxShadow: "0px 3px 6px rgba(0,0,0,0.29)",

      _hover: {
        bg: "#e6f3f7",
        color: "blue.darkest"
      },
      _disabled: {
        boxShadow: "0px 3px 6px rgba(0,0,0,0.29)"
      }
    } },
    tertiary: {
      border: "1px solid black",
      color: "white",
      bg: "black",

      _hover: {
        bg: "white",
        color: "black"
      },
      _active: {
        bg: "gray.400",
        color: "black"
      }
    },
    danger: {
      border: "1px solid secondary.red",
      color: "white",
      bg: "secondary.red",

      _hover: {
        bg: "red.light",
        color: "white",
        _disabled: {
          bg: "secondary.red"
        }
      },
      _active: {
        bg: "red.light",
        color: "white"
      }
    },
    // IconButton uses Button theme https://github.com/chakra-ui/chakra-ui/issues/3746
    iconGray: {
      borderRadius: 4,
      border: "1px solid #d9d9d9",
      bg: "#f2f4f4",
      color: "black",
      _hover: {
        bg: "white"
      }
    }
  },
  defaultProps: {
    // colorScheme: "blue",
    size: "md",
    variant: "primary"
  }
};
