import { createSelector } from "reselect";

import { STATUS_TYPES } from "constants/status-types";

export function getListings(state) {
  return state.listings;
}

export function getListingsType(state) {
  return getListings(state).type;
}

export function getListingsSearch(state) {
  return getListings(state).search;
}

export function getListingsStatus(state) {
  return getListings(state).status;
}

export function getAllListings(state) {
  return getListings(state).allListings;
}

export function getAllListingsStatus(state) {
  return getAllListings(state).status;
}

export function getIsAllListingsLoaded(state) {
  return getAllListingsStatus(state) === STATUS_TYPES.LOADED;
}

export function getAllListingsData(state) {
  return getAllListings(state).data;
}

export function getAllListingsDataCount(state) {
  return getAllListingsData(state).count;
}

export function getListingsByBrand(state) {
  return getListings(state).byBrand;
}

export function getListingsByBrandData(state) {
  return getListings(state).byBrand.data;
}

export function getListingsByBrandDataListings(state) {
  return getListingsByBrandData(state).listings;
}

export function getListingsByBrandDataRequests(state) {
  return getListingsByBrandData(state).requests;
}

export function getListingsByBrandValuesArray(state) {
  return Object.values(getListingsByBrandData(state).listings);
}

export function getListingsByBrandStatus(state) {
  return getListingsByBrand(state).status;
}

export function getListingsByRetailer(state) {
  return getListings(state).byRetailer;
}

export function getListingsByRetailerData(state) {
  return getListingsByRetailer(state).data || {};
}

export function getListingsByRetailerDataListings(state) {
  return getListingsByRetailerData(state).listings || {};
}

export function getListingsByRetailerStatus(state) {
  return getListingsByRetailer(state).status;
}

export function getListingsByRetailerIdAndListingTypeStatus({ state, resourceId, listingsType }) {
  return getListingsByRetailer(state).status[listingsType][resourceId];
}

export function getIsOwnListingsLoaded(state) {
  return getListingsByRetailer(state).statusOwnListings === STATUS_TYPES.LOADED;
}

export function getListingsByBrandAndType(state, brandId, listingType) {
  return getListingsByBrandData(state)[listingType][brandId];
}

export function getListingsByRetailerAndType(state, retailerId, listingType) {
  return (getListingsByRetailerData(state)[listingType] || {})[retailerId];
}

export function getListingsByBrandAndTypeStatus(state, resourceId, listingsType) {
  return getListingsByBrandStatus(state)[listingsType][resourceId];
}

export function getIsListingsByBrandAndTypeLoaded(state, resourceId, listingsType) {
  return getListingsByBrandAndTypeStatus(state, resourceId, listingsType) === STATUS_TYPES.LOADED;
}

export function getIsListingsBrandLoaded(state, resourceId, listingsType) {
  const listingsDataBrand = getListingsByBrandStatus(state)[listingsType][resourceId];

  return listingsDataBrand !== undefined && listingsDataBrand === STATUS_TYPES.LOADED;
}

export function getIsListingsRetailerLoaded(state, resourceId, listingsType) {
  const listingsDataRetailer = getListingsByRetailerStatus(state)[listingsType][resourceId];

  return listingsDataRetailer !== undefined && listingsDataRetailer === STATUS_TYPES.LOADED;
}

// TODO: Issue MRP-2773
// eslint-disable-next-line max-params
export function getListingsByBrandOrRetailer(state, id, listingType, isByRetailer) {
  if (isByRetailer) return getListingsByRetailerAndType(state, id, listingType);
  return getListingsByBrandAndType(state, id, listingType);
}

export const getTotalListingsCount = createSelector(getListingsByBrandValuesArray, items =>
  // eslint-disable-next-line no-param-reassign, no-return-assign, unicorn/no-array-reduce
  items.reduce((acc, item) => (acc += item.count), 0)
);

export function getIsSearch(state) {
  return Boolean(getListingsSearch(state));
}

export function getOwnListingsResults(state) {
  return Object.values(getListingsByRetailerDataListings(state))[0]?.results;
}
