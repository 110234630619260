import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { useRequireRetailerGuard } from "hooks/user";
import { errorToast } from "shared/toast-service";
import { getIsLoggedIn } from "store/selectors/auth";
import { getUserIsStaff } from "store/selectors/user";

export const PrivateRoute = ({ ...rest }) => {
  useRequireRetailerGuard();

  const location = useLocation();
  const isLoggedIn = useSelector(getIsLoggedIn);

  const redirectTo = `${location.pathname}${location.search}`;

  if (!isLoggedIn) {
    // TODO: Issue MRP-2768
    // eslint-disable-next-line no-undef
    localStorage.setItem("redirect", redirectTo);

    return (
      <Route {...rest}>
        <Redirect to={{ pathname: "/login", state: { from: redirectTo } }} />;
      </Route>
    );
  }

  return <Route {...rest} />;
};

export const AdminRoute = ({ ...rest }) => {
  const isAdmin = useSelector(getUserIsStaff);

  if (!isAdmin) {
    errorToast({ title: "Sorry!", description: "You do not have access to that page" });

    return (
      <Route {...rest}>
        <Redirect to="/" />;
      </Route>
    );
  }

  return <PrivateRoute {...rest} />;
};
