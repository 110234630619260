import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

import mapUniqueListings from "mappers/listings";

const emptyGroup = {
  results: []
};

const initialState = {
  search: "",
  category: "",
  status: "",
  allListings: {
    status: STATUS_TYPES.INITIAL,
    data: {}
  },
  byBrand: {
    status: {
      listings: {},
      requests: {}
    },
    data: {
      listings: {},
      requests: {}
    }
  },
  byRetailer: {
    statusOwnListings: "",
    status: {
      listings: {},
      requests: {}
    },
    data: {
      listings: {},
      requests: {}
    }
  }
};

export default function listingsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.LISTINGS.RESET:
      return {
        ...state,
        status: STATUS_TYPES.INITIAL,
        byBrand: initialState.byBrand,
        byRetailer: initialState.byRetailer,
        allListings: initialState.allListings
      };
    case ACTION_TYPES.LISTINGS.RESET_ALL_LISTINGS:
      return {
        ...state,
        allListings: initialState.allListings
      };

    case ACTION_TYPES.LISTINGS.RESET_SPECIFIC:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const { by, listingsType, resourceId: id } = action.payload;

      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const typeLabel = by === "brand" ? "byBrand" : "byRetailer";

      return {
        ...state,
        [typeLabel]: {
          ...state[typeLabel],
          status: {
            ...state[typeLabel].status,
            [listingsType]: {
              ...state[typeLabel].status[listingsType],
              [id]: "PENDING"
            }
          },
          data: {
            ...state[typeLabel].data,
            [listingsType]: {
              ...state[typeLabel].data[listingsType],
              [id]: { results: [] }
            }
          }
        }
      };

    case ACTION_TYPES.LISTINGS.SET_STATUS:
      return { ...state, status: action.payload };

    case ACTION_TYPES.LISTINGS.SET_SEARCH:
      return { ...state, search: action.payload };

    case ACTION_TYPES.LISTINGS.SET_CATEGORY:
      return { ...state, category: action.payload };

    case ACTION_TYPES.LISTINGS.SET_TYPE:
      return { ...state, type: action.payload };

    case ACTION_TYPES.LISTINGS.SET_DATA_ALL_LISTINGS:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const { payload } = action;
      return {
        ...state,
        allListings: {
          ...state.allListings,
          data: payload
        }
      };

    case ACTION_TYPES.LISTINGS.SET_DATA_BY_BRAND: {
      const { payload: resData, listingType, resourceId } = action;
      const { results } = resData;

      const changedListings = state.byBrand.data[listingType];
      const changedListingGroup = changedListings[resourceId] ?? emptyGroup;

      return {
        ...state,
        byBrand: {
          ...state.byBrand,
          data: {
            ...state.byBrand.data,
            [listingType]: {
              ...changedListings,
              [resourceId]: {
                ...changedListingGroup,
                ...resData,
                results: mapUniqueListings([...changedListingGroup.results, ...results]),
                status: STATUS_TYPES.LOADED
              }
            }
          }
        }
      };
    }

    case ACTION_TYPES.LISTINGS.SET_DATA_BY_RETAILER: {
      const { payload: resDataByRet, listingType, resourceId } = action;
      const { results } = resDataByRet;

      const changedListings = state.byRetailer.data[listingType];
      const changedListingGroup = changedListings[resourceId] || emptyGroup;

      return {
        ...state,
        byRetailer: {
          ...state.byRetailer,
          data: {
            ...state.byRetailer.data,
            [listingType]: {
              ...changedListings,
              [resourceId]: {
                ...changedListingGroup,
                ...resDataByRet,
                results: mapUniqueListings([...changedListingGroup.results, ...results]),
                status: STATUS_TYPES.LOADED
              }
            }
          }
        }
      };
    }

    case ACTION_TYPES.LISTINGS.SET_STATUS_ALL_LISTINGS:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          status: action.status
        }
      };

    case ACTION_TYPES.LISTINGS.SET_STATUS_BY_BRAND:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const changedListingStatus = state.byBrand.status[action.listingType];

      return {
        ...state,
        byBrand: {
          ...state.byBrand,
          status: {
            ...state.byBrand.status,
            [action.listingType]: {
              ...changedListingStatus,
              [action.resourceId]: action.status
            }
          }
        }
      };

    case ACTION_TYPES.LISTINGS.SET_STATUS_BY_RETAILER:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const changedListingByRetailerStatus = state.byRetailer.status[action.listingType];

      return {
        ...state,
        byRetailer: {
          ...state.byRetailer,
          status: {
            ...state.byRetailer.status,
            [action.listingType]: {
              ...changedListingByRetailerStatus,
              [action.resourceId]: action.status
            }
          }
        }
      };

    case ACTION_TYPES.LISTINGS.SET_STATUS_OWN_LISTINGS:
      return {
        ...state,
        byRetailer: {
          ...state.byRetailer,
          statusOwnListings: action.status
        }
      };

    default:
      return state;
  }
}
