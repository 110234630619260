import { isEmpty } from "lodash";

import { ACTION_TYPES } from "constants/action-types";

export function sessionStorageMiddleware({ getState }) {
  return next => action => {
    const { user } = getState();
    const isStaff = user.data.is_staff;

    if (action.type === ACTION_TYPES.AUTH.SET_RETAILER && isStaff) {
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      sessionStorage.setItem("retailerId", action.retailerId);
      window.location.reload();
    } else if (action.type === ACTION_TYPES.AUTH.UNSET_RETAILER) {
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      sessionStorage.setItem("retailerId", "");

      // Only reload the page if not logging out
      if (!isEmpty(user.data)) {
        window.location.reload();
      }
    }

    return next(action);
  };
}
