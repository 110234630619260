import { WINDOW_WIDTH_MOBILE, WINDOW_WIDTH_TABLET } from "constants/layout/layout";

export const LISTINGS_FILTER_STATUSES = {
  ACTIVE: "active",
  ALL: "all"
};
/** @type {TListingsTabs} */
export const LISTINGS_TABS = [
  {
    id: "brand",
    label: "View products by brand",
    sublabel: {
      singular: "listing",
      plural: "listings"
    },
    link: "/listings"
  },
  {
    id: "retailer",
    label: "View products by retailer",
    sublabel: {
      singular: "retailer",
      plural: "retailers"
    },
    link: "/listingsByRetailer"
  }
];

/** @type {TListingsTabs} */
export const MY_LISTINGS_TABS = [
  {
    id: "listings",
    label: "Listed"
  },
  {
    id: "requests",
    label: "Requests"
  }
];

export const MY_LISTINGS_TABS_WITH_ERROR_REPORT = [
  MY_LISTINGS_TABS[0],
  {
    id: "pending",
    label: "Pending"
  },
  MY_LISTINGS_TABS[1]
];

/** @type {TListingsTabs} */
export const LISTINGS_BY_RETAILER_TABS = [
  {
    id: "listings",
    label: "Their Listings",
    sublabel: {
      singular: "product listed",
      plural: "products listed"
    }
  },
  {
    id: "requests",
    label: "Their Requests",
    sublabel: {
      singular: "product needed",
      plural: "products needed"
    }
  }
];

/** @type {TListingsTabs} */
export const BROWSE_TABS = [
  {
    id: "brand",
    label: "by Brand",
    sublabel: {
      singular: "product listed",
      plural: "products listed"
    },
    link: "/#"
  },
  {
    id: "retailer",
    label: "by Retailer",
    sublabel: {
      singular: "authorized retailer",
      plural: "authorized retailers"
    },
    link: "/#"
  }
];

export const LISTINGS_PER_PAGE = {
  CAROUSEL: 8,
  EXPANDED: 24
};

/**
 * This is the max price that can be entered in the listing filters input. Numbers
 * greater than this will be converted to undefined, making them unlimited.
 */
export const MAX_LISTINGS_PRICE = 10 ** 6;

export const PRODUCTS_PER_SLIDE = {
  desktop: 4,
  tablet: 2,
  mobile: 1
};

export const PRODUCTS_PER_DROPDOWN = 50;

export const LISTING_CONFIRMATION_TEXT = {
  listing: "Success! Your product is now posted for other retailers to see. Wasn't that easy? List another now.",
  request: `Success! Your request has been posted.
     You'll be notified if it's listed by another retailer. Browse what other retailers have listed now.`
};

export const LISTINGS_SLIDER_SETTINGS = {
  slidesToShow: PRODUCTS_PER_SLIDE.desktop,
  slidesToScroll: PRODUCTS_PER_SLIDE.desktop,
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: WINDOW_WIDTH_TABLET,
      settings: {
        slidesToShow: PRODUCTS_PER_SLIDE.tablet,
        slidesToScroll: PRODUCTS_PER_SLIDE.tablet
      }
    },
    {
      breakpoint: WINDOW_WIDTH_MOBILE,
      settings: {
        slidesToShow: PRODUCTS_PER_SLIDE.mobile,
        slidesToScroll: PRODUCTS_PER_SLIDE.mobile
      }
    }
  ]
};

// TODO: remove when we have endpoint
export const LISTINGS_CATEGORIES = {
  actions: {
    POST: {
      category: {
        choices: [
          {
            value: "dresses",
            display_name: "Dresses"
          },
          {
            value: "blouses",
            display_name: "Blouses"
          },
          {
            value: "cardigans",
            display_name: "Cardigans"
          },
          {
            value: "heavy outerwear/furs",
            display_name: "Heavy Outerwear/Furs"
          },
          {
            value: "jackets and vests",
            display_name: "Jackets and Vests"
          },
          {
            value: "jeans",
            display_name: "Jeans"
          },
          {
            value: "jumpsuits and rompers",
            display_name: "Jumpsuits and Rompers"
          },
          {
            value: "pants",
            display_name: "Pants"
          },
          {
            value: "shorts",
            display_name: "Shorts"
          },
          {
            value: "skirts",
            display_name: "Skirts"
          },
          {
            value: "sweaters",
            display_name: "Sweaters"
          },
          {
            value: "swim",
            display_name: "Swim"
          },
          {
            value: "teeshirts",
            display_name: "TeeShirts"
          },
          {
            value: "other",
            display_name: "Other"
          }
        ]
      }
    }
  }
};

export const SHOW_PATTER_SUBCATEGORIES = ["coats", "jackets", "blazers", "dresses", "sweaters", "swimsuits"];
