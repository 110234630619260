import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";

const initialState = {
  items: {},
  transactions: {},
  status: STATUS_TYPES.INITIAL
};

// TODO: Issue MRP-2773
// eslint-disable-next-line no-undef
const LSCart = JSON.parse(localStorage.getItem("cart"));
const lsCartItems = Object.values(LSCart ? LSCart.items || {} : {});

if (LSCart && lsCartItems.length === 0) {
  LSCart.status = STATUS_TYPES.INITIAL;
}

export default function cartReducer(state = LSCart && LSCart.transactions ? LSCart : initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CART.SET_STATUS: {
      return { ...state, status: action.payload };
    }

    case ACTION_TYPES.CART.ADD_ITEM: {
      const { item, units } = action.payload;

      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      const localStorageCart = JSON.parse(localStorage.getItem("cart")) || state;
      const { items } = localStorageCart;

      const itemsCopy = { ...items };
      const listingId = item.id;

      const existingItem = itemsCopy[listingId];

      if (existingItem) {
        const existingUnits = existingItem.units;

        for (const [unitSizeName, unitCount] of Object.entries(units)) {
          existingUnits[unitSizeName] = unitCount;
        }

        return { ...state, items: itemsCopy };
      }

      itemsCopy[listingId] = { ...item, units };

      return { ...localStorageCart, items: itemsCopy };
    }

    case ACTION_TYPES.CART.MODIFY_ITEM: {
      const { item, units } = action.payload;

      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      const localStorageCart = JSON.parse(localStorage.getItem("cart")) || state;
      const { items } = localStorageCart;

      const itemsCopy = { ...items };

      const existingItem = items[item.id];

      if (existingItem) {
        itemsCopy[item.id].units = units;
        // eslint-disable-next-line no-param-reassign, no-return-assign, unicorn/no-array-reduce
        itemsCopy[item.id].totalUnits = Object.values(units).reduce((acc, unitsNo) => (acc += unitsNo), 0);
        itemsCopy[item.id].totalPrice = itemsCopy[item.id].totalUnits * Number(item.unit_price);

        return { ...localStorageCart, items: itemsCopy };
      }

      return { ...localStorageCart };
    }

    case ACTION_TYPES.CART.REMOVE_ITEM: {
      const { itemId } = action.payload;

      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      const localStorageCart = JSON.parse(localStorage.getItem("cart")) || state;
      const { items } = localStorageCart;

      const existingItem = items[itemId];

      if (existingItem) {
        const itemsCopy = { ...items };
        delete itemsCopy[itemId];

        return { ...localStorageCart, items: itemsCopy };
      }

      return { ...localStorageCart };
    }

    case ACTION_TYPES.CART.REMOVE_BY_RETAILER_ID: {
      const retailerId = action.payload;

      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-undef
      const localStorageCart = JSON.parse(localStorage.getItem("cart")) || state;
      const { items } = localStorageCart;

      const newItemsObject = {};

      for (const [cartitemId, cartItem] of Object.entries(items)) {
        if (Number(cartItem.provider) !== Number(retailerId)) {
          newItemsObject[cartitemId] = cartItem;
        }
      }

      return { ...localStorageCart, items: newItemsObject };
    }

    case ACTION_TYPES.CART.CLEAR_CART:
      return initialState;

    case ACTION_TYPES.CART.SET_TRANSACTION:
      // TODO: Issue MRP-2773
      // eslint-disable-next-line no-case-declarations
      const { retailerId, data } = action.payload;
      if (!data) {
        const transactionsCopy = { ...state.transactions };
        delete transactionsCopy[retailerId];
        return { ...state, transactions: transactionsCopy };
      }
      return { ...state, transactions: { ...state.transactions, [retailerId]: data } };

    default:
      return state;
  }
}
