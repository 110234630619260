export default function mapBrands(result) {
  const brandsObject = {
    asArray: [],
    byID: {}
  };

  const param = result.results ? 'results' : 'mutual_brands'

  brandsObject.asArray = result[param].map(brand => {
    const brandWithId = {
      ...brand,
      id: brand.brand || brand.id
    };

    brandsObject.byID[brandWithId.id] = brandWithId;

    return brandWithId;
  });

  return brandsObject;
}
