import authAPI from "api/auth-api";
import { ACTION_TYPES } from "constants/action-types";
import { isValidJwtToken } from "shared/user-helpers";

export async function reloadJwtAccessToken() {
  // TODO: Issue MRP-2773
  // eslint-disable-next-line no-undef
  const refreshToken = localStorage.getItem("refreshToken");

  // Don't try to reload token if invalid
  if (!isValidJwtToken(refreshToken)) return "";

  // Reload and set access token
  try {
    const result = await authAPI.refreshToken(refreshToken);
    // TODO: Issue MRP-2773
    // eslint-disable-next-line no-undef
    localStorage.setItem("accessToken", result.access);
    return result.access;
  } catch {
    // TODO: Issue MRP-2773
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
}

export function localStorageMiddleware({ getState }) {
  return next => action => {
    switch (action.type) {
      case ACTION_TYPES.AUTH.SET_TOKEN:
        // TODO: Issue MRP-2773
        // eslint-disable-next-line no-undef
        localStorage.setItem("refreshToken", action.payload.refresh);
        // TODO: Issue MRP-2773
        // eslint-disable-next-line no-undef
        localStorage.setItem("accessToken", action.payload.access);
        break;
      case ACTION_TYPES.AUTH.UNSET_HUBSPOT:
        // TODO: Issue MRP-2773
        // eslint-disable-next-line unicorn/no-document-cookie
        document.cookie = "hubspotutk=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        break;

      case ACTION_TYPES.AUTH.UNSET_TOKEN:
        // TODO: Issue MRP-2773
        // eslint-disable-next-line no-undef
        localStorage.setItem("refreshToken", "");
        // TODO: Issue MRP-2773
        // eslint-disable-next-line no-undef
        localStorage.setItem("accessToken", "");
        break;

      case ACTION_TYPES.CART.ADD_ITEM:
      case ACTION_TYPES.CART.MODIFY_ITEM:
      case ACTION_TYPES.CART.REMOVE_ITEM:
      case ACTION_TYPES.CART.REMOVE_BY_RETAILER_ID: {
        setTimeout(() => {
          const { cart } = getState();
          if (cart) {
            // TODO: Issue MRP-2773
            // eslint-disable-next-line no-undef
            localStorage.setItem("cart", JSON.stringify(cart));
          }
        }, 500);
        return next(action);
      }

      case ACTION_TYPES.CART.CLEAR_CART: {
        // TODO: Issue MRP-2773
        // eslint-disable-next-line no-undef
        localStorage.removeItem("cart");
        break;
      }

      default:
        return next(action);
    }
    return next(action);
  };
}
