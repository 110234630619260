import { useDisclosure } from "@chakra-ui/react";

// layout components
import SideNav from "components/layout/side-nav";
import {
  MobileNavbar,
  ScrollableContent,
  StyleLayoutContainer,
  StyleMainContentContainer,
  StyleSideNavContainer
} from "components/layout/side-nav-styles";
import LockedAccountNotification from "components/shared/locked-account-notification";
import { useDashboardStats } from "areas/dashboard/hooks";

const SideNavLayout = ({ children, hasSidenav = true }) => {
  const { isOpen, onOpen: openSidenav, onClose: closeSidenav } = useDisclosure({ defaultIsOpen: true });
  const { isLockedAccount } = useDashboardStats();

  return (
    <StyleLayoutContainer>
      <MobileNavbar onOpen={openSidenav} />

      {hasSidenav && (
        <StyleSideNavContainer isOpen={isOpen}>
          <SideNav onClose={closeSidenav} />
        </StyleSideNavContainer>
      )}

      <StyleMainContentContainer>
        {isLockedAccount && <LockedAccountNotification />}
        <ScrollableContent>{children}</ScrollableContent>
      </StyleMainContentContainer>
    </StyleLayoutContainer>
  );
};

export default SideNavLayout;
