import ReactDOM from "react-dom";

import userAPI from "api/user-api";
import { ACTION_TYPES } from "constants/action-types";
// TODO: Issue MRP-2773
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";
import { isValidJwtToken } from "shared/user-helpers";
import MaintenanceAlert from "areas/login/maintenance-alert";

import { reloadJwtAccessToken } from "store/middlewares/local-storage-middleware";

import { unsetToken } from "./auth";
import { setError } from "./errors";

const HTTP_UNAUTHORIZED = 401;
const TOKEN_NOT_VALID = "token_not_valid";

function clearTokens(dispatch) {
  dispatch(unsetToken());
  dispatch(setStatus(STATUS_TYPES.FAILED));
}

function handleUserDataError(error, dispatch) {
  if (error?.status === HTTP_UNAUTHORIZED || error?.data?.code === TOKEN_NOT_VALID) {
    clearTokens(dispatch);
  } else {
    showMaintenanceAlert();
    dispatch(setStatus(STATUS_TYPES.FAILED));
  }
}

function showMaintenanceAlert() {
  ReactDOM.render(MaintenanceAlert(), document.querySelector("#root"));
}

export function setUserData(data) {
  return {
    type: ACTION_TYPES.USER.SET_DATA,
    payload: data
  };
}

export function unsetData() {
  return {
    type: ACTION_TYPES.USER.UNSET_DATA
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.USER.SET_STATUS,
    payload: status
  };
}

export function setSellerType(sellerType) {
  return {
    type: ACTION_TYPES.USER.SET_SELLER_TYPE,
    payload: sellerType
  };
}

export function requestUserDataIfToken() {
  return async dispatch => {
    // TODO: Issue MRP-2773
    // eslint-disable-next-line no-undef
    const accessToken = localStorage.getItem("accessToken");
    // TODO: Issue MRP-2773
    // eslint-disable-next-line no-undef
    const refreshToken = localStorage.getItem("refreshToken");

    // If refresh token is invalid or expired, fail the user session
    // and force the user to re-login
    if (!isValidJwtToken(refreshToken)) {
      return clearTokens(dispatch);
    }

    // If the access token is invalid but refresh token is valid, reload
    // the user's access token first and then trigger a user load
    if (!isValidJwtToken(accessToken)) {
      const newToken = await reloadJwtAccessToken();
      if (isValidJwtToken(newToken)) {
        return dispatch(requestUserData());
      }
      return dispatch(setStatus(STATUS_TYPES.FAILED));
    }

    // If the access token is present and valid, load the user data immediately
    // with the current token and update the access token in the background
    if (isValidJwtToken(accessToken)) {
      dispatch(requestUserData());
      return reloadJwtAccessToken();
    }

    return dispatch(setStatus(STATUS_TYPES.FAILED));
  };
}

export function reloadUserData() {
  return async dispatch => {
    try {
      const userData = await userAPI.fetchUserData();

      return dispatch(setUserData(userData));
    } catch (error) {
      handleUserDataError(error, dispatch);

      return dispatch(setError({ type: "user", errors: [error?.payload?.detail] }));
    }
  };
}

export function requestUserData() {
  return async dispatch => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    try {
      const userData = await userAPI.fetchUserData();
      dispatch(setUserData({ ...userData }));

      return dispatch(setStatus(STATUS_TYPES.LOADED));
    } catch (error) {
      handleUserDataError(error, dispatch);

      return dispatch(setError({ type: "user", errors: [error?.payload?.detail] }));
    }
  };
}
