import { createStandaloneToast } from "@chakra-ui/react";

import { theme } from "ui-kit";

const FIVE_SECONDS = 5000;

const toast = createStandaloneToast({
  theme,
  defaultOptions: {
    variant: "top-accent",
    duration: FIVE_SECONDS,
    position: "top",
    isClosable: true
  }
});

/** @type {typeof toast} */
export const successToast = ({ status = "success", ...rest }) => {
  toast({
    status,
    ...rest
  });
};

/** @type {typeof toast} */
export const errorToast = ({ status = "error", ...rest }) => {
  toast({
    status,
    ...rest
  });
};

/** @type {typeof toast} */
export const infoToast = ({ status = "info", ...rest }) => {
  toast({
    status,
    ...rest
  });
};
