// TODO: Issue MRP-2774
// eslint-disable-next-line import/no-unresolved
import { FormNumber } from "ui-kit/form/number";
// TODO: Issue MRP-2774
// eslint-disable-next-line import/no-unresolved
import { useSmartInput } from "ui-kit/form/smart/use-smart-input";

import FormInput from "../input/input";
import FormPassword from "../password-input/password-input";

export const SmartInput = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormInput
      // TODO: Issue MRP-2774
      // eslint-disable-next-line no-implicit-coercion
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};

export const SmartPassword = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormPassword
      // TODO: Issue MRP-2774
      // eslint-disable-next-line no-implicit-coercion
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};

export const SmartNumber = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormNumber
      // TODO: Issue MRP-2774
      // eslint-disable-next-line no-implicit-coercion
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};