import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as React from "react";

import popupService from "./shared/popup-service";
import sentryService from "./shared/sentry-service";

import AppRouter from "./components/app-router";
import Versioning from "./components/versioning";
import store from "./store/reducers";

import { BaseProviders } from "./components/app-providers";

// Vendor CSS
// TODO: Issue MRP-2775
// eslint-disable-next-line import/first
import "slick-carousel/slick/slick.css";

// App CSS
// TODO: Issue MRP-2775
// eslint-disable-next-line import/first
import "style/styles.scss";
import * as serviceWorker from "./service-worker";

sentryService.init();
popupService.attachToStore(store);

ReactDOM.render(
  <BaseProviders>
    <Provider store={store}>
      <Versioning>
        <AppRouter />
      </Versioning>
    </Provider>
  </BaseProviders>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
