export default {
  baseStyle: ({ colorScheme: c }) => { return {
    bg: `${c}.400`,
    borderColor: `${c}.600`,
    borderRadius: "10px",
    borderWidth: "2px",
    color: "white",
    display: "table-cell",
    px: "0.25em",
    py: "0.04em",
    textStyle: "body",
    verticalAlign: "middle"
  } },
  defaultProps: {
    variant: "notification",
    colorScheme: "green"
  }
};
