import { Flex, Image } from "@chakra-ui/react";
import { LogoNoSwap } from "static/images";

// TODO: Issue MRP-2775
// eslint-disable-next-line arrow-body-style
const HeaderWithoutSideNav = () => {
  return (
    <Flex
      justify="center"
      bg="white"
      shadow="lg"
      zIndex="sticky"
      p="5"
      pos="fixed"
      top={0}
      w="100vw"
      h="91"
      box-shadow="0px 3px 6px #00000029"
    >
      <a href="/">
        <Image src={LogoNoSwap} loading="eager" alt="Max Retail logo" maxWidth="150px" margin="auto" />
      </a>
    </Flex>
  );
};

export default HeaderWithoutSideNav;
