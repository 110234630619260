import { isEmpty } from "lodash";
import { lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useLocalStorage, useMount, useSearchParam } from "react-use";

import { LINK_KNOWLEDGE_CENTER } from "constants/contact";
import { MY_BRANDS } from "constants/side-bars.const";

// TODO: Issue MRP-2775
// eslint-disable-next-line import/no-named-as-default
import STATUS_TYPES from "constants/status-types";
import { identify, PageTracking } from "shared/segment";

import { AdminRoute, PrivateRoute } from "components/app-routes";
import ErrorCatcher from "components/error-catcher";
import Layout from "components/layout/layout";
import SitewideAlertBanner from "components/layout/sitewide-alert-banner";
import Loader from "components/shared/loader/loader";
import ScrollToTop from "components/shared/scroll-to-top";
import SuspenseLoader from "components/suspense-loader";
import DiscountOldListingPage from "pages/discount-old-listing/discount-old-listing-page";
import { useSelector } from "react-redux";
import { getUserSellerType } from "store/selectors/user";

const AdminProductCatalogArea = lazy(() => import("areas/admin/product-catalog"));
const AdminRTRUploadArea = lazy(() => import("areas/admin/rtr/rtr-upload"));
const AdminCSVUploadArea = lazy(() => import("areas/mr-generic-csv-upload/csv-upload"));
const BlogsArea = lazy(() => import("areas/blogs"));
const BrandsArea = lazy(() => import("areas/brands"));
const DashboardArea = lazy(() => import("areas/dashboard"));
const LoginArea = lazy(() => import("areas/login"));
const LogoutArea = lazy(() => import("areas/logout"));
const RegisterArea = lazy(() => import("areas/register"));
const OrderArea = lazy(() => import("areas/order"));
const OrdersArea = lazy(() => import("areas/orders"));
const PurchasesArea = lazy(() => import("areas/orders/purchases"));
const RequestListing = lazy(() => import("areas/request-listing"));
const ResetPasswordArea = lazy(() => import("areas/reset-password"));
const SalesArea = lazy(() => import("areas/orders/sales"));
const SettingsArea = lazy(() => import("areas/settings"));
const VerificationsArea = lazy(() => import("areas/verifications"));
const MatchingArea = lazy(() => import("areas/matching"));
const ImageVerificationArea = lazy(() => import("areas/image-verification"));

const PIMArea = lazy(() => import("areas/pim"));
const PIMVariantDetailPage = lazy(() => import("areas/pim/variant-detail"));

const LightspeedRSeries = lazy(() => import("pages/lightspeed-r-series"));
const LightspeedRSeriesSuccess = lazy(() => import("pages/lightspeed-r-series-success"));
const LightspeedRSeriesError = lazy(() => import("pages/lightspeed-r-series-error"));
const ShopifySuccess = lazy(() => import("pages/shopify-success"));
const ShopifyError = lazy(() => import("pages/shopify-error"));

const AddListingPage = lazy(() => import("pages/add-listing-page"));
const BrowsePage = lazy(() => import("pages/browse-page"));
const SearchPage = lazy(() => import("pages/search-page"));
const CheckoutPage = lazy(() => import("pages/checkout-page"));
const ListingPage = lazy(() => import("pages/listing-page"));
const ListingsPage = lazy(() => import("pages/listings-page"));

const TermsAndConditions = lazy(() => import("areas/legal/terms"));
const PrivacyPolicy = lazy(() => import("areas/legal/privacy"));
const PricingDrops = lazy(() => import("areas/pricing"));
const ReferFriend = lazy(() => import("areas/refer/refer-friend"));

const AppRouter = props => {
  const { pathname } = useLocation();
  const psxid = useSearchParam("ps_xid");
  const [_, setValue] = useLocalStorage("psxid");
  const isDiscountOldPath = pathname.toLowerCase().includes("/settings/discountold");
  const { doGetUserData, userStatus, user } = props;
  const getSellerType = useSelector(getUserSellerType);
  const [hasCheckedAuthentication, setHasCheckedAuthentication] = useState(false);

  useMount(() => doGetUserData());

  useEffect(() => {
    if (!hasCheckedAuthentication && (userStatus === STATUS_TYPES.LOADED || userStatus === STATUS_TYPES.FAILED)) {
      setHasCheckedAuthentication(true);
      identify(user);
    }
  }, [hasCheckedAuthentication, userStatus, user]);

  useEffect(() => {
    if (psxid) {
      setValue(psxid);
    }
  }, [psxid]);

  if (isDiscountOldPath) {
    return (
      <>
        {!hasCheckedAuthentication && <Loader type="fullScreen" size="large" isVisible isLoadingTextVisible />}
        {hasCheckedAuthentication && (
          <SuspenseLoader>
            <PrivateRoute path="/settings/discountold">
              <DiscountOldListingPage />
            </PrivateRoute>
          </SuspenseLoader>
        )}
      </>
    );
  }

  return <>
    <ScrollToTop />
    <PageTracking />
    <ErrorCatcher>
      <SitewideAlertBanner isLoggedIn={!isEmpty(user)} />
      {!hasCheckedAuthentication && <Loader type="fullScreen" size="large" isVisible isLoadingTextVisible />}
      {hasCheckedAuthentication && (
        <Layout showSidenav={!isEmpty(user)}>
          <SuspenseLoader>
            <Switch>
              <Route
                path={["/password-reset/:uid/:token", "/password-reset"]}
                render={routeProps => (isEmpty(user) ? <ResetPasswordArea {...routeProps} /> : <Redirect to="/" />)}
              />
              <Route
                path="/login"
                render={routeProps => (isEmpty(user) ? <LoginArea {...routeProps} /> : <Redirect to="/" />)}
              />
              <Route path="/logout" render={() => (isEmpty(user) ? <Redirect to="/login" /> : <LogoutArea />)} />
              <Route
                path="/register"
                render={() => (isEmpty(user) ? <RegisterArea /> : <Redirect to="/?success=true" />)}
              />
              <PrivateRoute path="/cart">
                <CheckoutPage />
              </PrivateRoute>
              <PrivateRoute path="/browse/:page?" render={routeProps => <BrowsePage {...routeProps} />} />
              <PrivateRoute path="/brands/:tab" component={BrandsArea} />
              <PrivateRoute path="/brands/" exact>
                <Redirect to={MY_BRANDS.url} />
              </PrivateRoute>
              <PrivateRoute path="/settings" component={SettingsArea} />
              <PrivateRoute path="/addListing" component={AddListingPage} />
              <PrivateRoute path="/requestListing" component={RequestListing} />
              <PrivateRoute path="/listing/buy/:id">
                <ListingPage type="buy" />
              </PrivateRoute>
              <PrivateRoute path="/listing/sell/:id">
                <ListingPage type="sell" />
              </PrivateRoute>
              <PrivateRoute path="/listing/update/:id">
                <ListingPage type="mine" />
              </PrivateRoute>
              <PrivateRoute
                path="/request/update/:id"
                render={routeProps => <ListingPage type="mineRequest" {...routeProps} />}
              />
              {/* TODO: Doesn't seem to work. Confirm that this page is dead and can be removed */}
              <PrivateRoute path="/sell" render={routeProps => <ListingsPage by="sell" {...routeProps} />} />
              <PrivateRoute
                path="/listings/brand/:brandId"
                render={routeProps => <ListingsPage isExpanded by="brand" type="listings" {...routeProps} />}
              />
              <PrivateRoute
                path="/requests/brand/:brandId"
                render={routeProps => <ListingsPage isExpanded by="brand" type="requests" {...routeProps} />}
              />
              <PrivateRoute
                path="/listings/retailer/:id"
                render={routeProps => <ListingsPage isListingByRetailer {...routeProps} />}
              />
              <PrivateRoute path="/listings/" render={routeProps => <ListingsPage by="brand" {...routeProps} />} />
              <PrivateRoute
                path="/myListings/"
                render={routeProps => <ListingsPage isOwnListings {...routeProps} />}
              />

              <PrivateRoute
                path="/orders"
                exact
                render={({ location: { search } }) => (
                  <Redirect
                    to={{
                      pathname: "/orders/pending/sales",
                      search
                    }}
                  />
                )}
              />
              <PrivateRoute path="/orders/:state/:type">
                <OrdersArea />
              </PrivateRoute>
              <PrivateRoute path="/orders/:id">
                <OrderArea />
              </PrivateRoute>
              <PrivateRoute path="/sales">
                <SalesArea />
              </PrivateRoute>
              <PrivateRoute path="/purchases">
                <PurchasesArea />
              </PrivateRoute>
              <PrivateRoute path="/dashboard">
                <DashboardArea />
              </PrivateRoute>
              <PrivateRoute path="/blogs">
                <BlogsArea />
              </PrivateRoute>

              <Route path="/shopify-success">
                <ShopifySuccess userAuthenticated={!isEmpty(user)} />
              </Route>
              <Route path="/shopify-error">
                <ShopifyError />
              </Route>
              <PrivateRoute path="/lightspeed-r-series">
                <LightspeedRSeries />
              </PrivateRoute>
              <PrivateRoute path="/lightspeed-r-series-success">
                <LightspeedRSeriesSuccess />
              </PrivateRoute>
              <PrivateRoute path="/lightspeed-r-series-error">
                <LightspeedRSeriesError />
              </PrivateRoute>

              <PrivateRoute
                path="/knowledge-center/"
                component={() => {
                  window.location.replace(LINK_KNOWLEDGE_CENTER);
                  // TODO: Issue MRP-2775
                  // eslint-disable-next-line unicorn/no-null
                  return null;
                }}
              />
              <Route path="/terms">
                <TermsAndConditions userAuthenticated={!isEmpty(user)} />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy userAuthenticated={!isEmpty(user)} />
              </Route>
              <PrivateRoute path="/pricing/" component={PricingDrops} />
              <PrivateRoute path="/refer-friend/" component={ReferFriend} />

              <Route exact path="/verifications" render={() => <Redirect to="/admin/verifications" />} />

              <AdminRoute path="/search/:page?" render={routeProps => <SearchPage {...routeProps} />} />

              <AdminRoute path="/admin/verifications">
                <VerificationsArea />
              </AdminRoute>

              <AdminRoute path="/admin/catalog">
                <AdminProductCatalogArea />
              </AdminRoute>

              <AdminRoute path="/admin/rtr">
                <AdminRTRUploadArea />
              </AdminRoute>

              <AdminRoute path="/admin/csv">
                <AdminCSVUploadArea />
              </AdminRoute>

              <AdminRoute path="/admin/image-verification">
                <ImageVerificationArea />
              </AdminRoute>

              <AdminRoute path="/admin/matching">
                <MatchingArea />
              </AdminRoute>

              <AdminRoute path="/admin/pim/detail/:variant_id">
                <PIMVariantDetailPage />
              </AdminRoute>

              <AdminRoute path="/admin/pim">
                <PIMArea />
              </AdminRoute>

              <Route path="/:page*" render={() => (isEmpty(user) ? <Redirect to="/login" /> : <DashboardArea />)} />
            </Switch>
          </SuspenseLoader>
        </Layout>
      )}
    </ErrorCatcher>
  </>;
};

export default AppRouter;
