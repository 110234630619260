import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Select as CSelect } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { CountryDropdown, CountryRegionData, RegionDropdown } from "react-country-region-selector";
import { Select, SelectAsync, SelectAsyncCreatable, SelectCreatable } from "ui-kit";

export const FormSelect = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    options,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <CSelect ref={ref} id={props.id ?? name} name={name} onChange={onChange} {...rest}>
        {options.map((o, i) => (
          <option key={`${o.label}-${i}`} value={o.value} selected={value === o.value}>
            {o.label}
          </option>
        ))}
      </CSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});

export const FormGeoSelect = forwardRef((props, ref) => {
  const {
    selectType = "chakra",
    type = "country",
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    ...rest
  } = props;

  const Tag = useMemo(() => (type === "country" ? CountryDropdown : RegionDropdown), [type]);
  const rsOptions = useMemo(() =>
    // TODO: Issue MRP-2774
    // eslint-disable-next-line arrow-body-style
    CountryRegionData.map(([countryName, countryShortCode]) => ({
      value: countryShortCode,
      label: countryName
    }))
  );
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      {selectType === "reactSelect" ? (
        <Select
          ref={ref}
          id={props.id ?? name}
          name={name}
          value={value}
          {...rest}
          options={rsOptions}
          getOptionLabel={color => color.label}
          getOptionValue={color => color.value}
          onChange={onChange}
          borderRadius="sm"
        />
      ) : (
        <CSelect as={Tag} ref={ref} id={props.id ?? name} name={name} value={value} onChange={onChange} {...rest} />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});

export const FormRegionSelect = props => {
  const {
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    options,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <RegionDropdown {...rest} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

/**
 * @type {typeof Select}
 */
export const FormReactSelect = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    options,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <Select
        ref={ref}
        id={props.id ?? name}
        name={name}
        value={value}
        // TODO: Issue MRP-2774
        // eslint-disable-next-line unicorn/no-null
        components={{ IndicatorSeparator: null }}
        onChange={onChange}
        options={options}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});

/**
 * @type {typeof SelectCreatable}
 */
export const FormSelectCreatable = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <SelectCreatable
        ref={ref}
        id={props.id ?? name}
        name={name}
        value={value}
        // TODO: Issue MRP-2774
        // eslint-disable-next-line unicorn/no-null
        components={{ IndicatorSeparator: null }}
        onChange={onChange}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});

/**
 * @type {typeof SelectAsync}
 */
export const FormSelectAsync = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    name,
    value,
    helperText,
    onChange,
    isInvalid,
    isRequired,
    errorMessage,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <SelectAsync
        ref={ref}
        id={props.id ?? name}
        name={name}
        value={value}
        // TODO: Issue MRP-2774
        // eslint-disable-next-line unicorn/no-null
        components={{ IndicatorSeparator: null }}
        onChange={onChange}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});

/**
 * @type {typeof SelectAsyncCreatable}
 */
export const FormSelectAsyncCreatable = forwardRef((props, ref) => {
  const { label, labelProps = {}, name, value, onChange, isInvalid, isRequired, errorMessage, ...rest } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <SelectAsyncCreatable
        ref={ref}
        id={props.id ?? name}
        name={name}
        value={{ label: value, value }}
        // TODO: Issue MRP-2774
        // eslint-disable-next-line unicorn/no-null
        components={{ IndicatorSeparator: null }}
        onChange={onChange}
        {...rest}
      />
      {isInvalid && <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});
