const UNITED_SIZE_MINIMUM = 650;

const MINIMUM_QUALITY_WIDTH = 250;
const MINIMUM_QUALITY_HEIGHT = 250;

/**
 * @param {string} url
 * @returns Promise<{ width: number, height: number}>
 */
export const getImageDimentionsFromUrl = url =>
  new Promise((resolve, reject) => {
    // TODO: Issue MRP-2772
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.addEventListener("load", () => {
      resolve({ width: img.width, height: img.height });
    });
    img.addEventListener("error", () => {
      // TODO: Issue MRP-2772
      // eslint-disable-next-line prefer-promise-reject-errors
      reject("Error loading image");
    });
    img.src = url;
  });

/**
 * @param {Image} image
 * @returns boolean
 */
export const validateImageDimensions = ({ width = 0, height = 0 }) => {
  if (width + height < UNITED_SIZE_MINIMUM) return false;
  if (width < MINIMUM_QUALITY_WIDTH) return false;
  if (height < MINIMUM_QUALITY_HEIGHT) return false;

  return true;
};

/**
 * @param {string} url
 * @returns Promise<boolean>
 */
export const validateImageDimensionsFromUrl = async url => {
  const dimensions = await getImageDimentionsFromUrl(url);
  const valid = validateImageDimensions(dimensions);
  if (!valid) {
    throw new Error("Image does not meet size requirements");
  }
};
